:root {
  --grey-lightest: #f7f9fa;
  --white: #ffffff;
  --grey: #c8d1dc;
  --dark-grey: #6b7785;
  --dark-blue: #1f2d3d;
  --dark-blue-border: #2b3f56;
  --green: #72cc18;
  --red-dark: #BB0C0C; 
  --font-family: GraphikRegular, 'Helvetica Neue', Sans-Serif;
}

/* CODIGO AGREGADO POR MI */

.btn-finalizarConsulta{
  margin: 0px 24px;
  width: 80%;
  background-color: var(--color-rojo);
  border: 0;
  border-radius: 8px;
  color: var(--white);
  height: 35px;
}

.btn-finalizarConsulta:hover{
  background-color: var(--color-rojo-hover);
  color:black
}

.btn-generarReceta{
  background-color: var(--color-primario);
  border: 0;
  border-radius: 8px;
  color: var(--white);
  padding: 15px 20%;
  text-decoration: none;
}

.btn-generarReceta:hover{
  background-color: var(--color-primario-hover);
  color:var(--white)
}

/*  */

body {
  margin: 0px;
  height: 100%;
}

.contain {
  width: 100%;
  height: calc(100% - 52px);
  background-color: var(--grey-lightest);
  position: relative;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  font-family: var(--font-family);
  font-size: 16px;
  line-height: 29px;
}

.left-header {
  display: flex;
  align-items: center;
  width: 325px;
  margin-left: 16px;
}

.daily-logo {
  height: 24px;
  width: 60px;
  margin-right: 24px;
}

.header-title {
  font-size: 12px;
  line-height: 16px;
  font-family: var(--font-family);
}

.right-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 24px;
}

.title {
  margin: 0;
  text-align: center;
}

.docs-button {
  justify-content: space-between;
}

.reload-button {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.arrow-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}

.controls-button {
  justify-content: center;
  padding: 4px 8px;
  height: 30px;
  margin: 4px;
}

.control-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}

.white-button {
  color: var(--dark-blue); 
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--white);
  border: 1px solid var(--grey);
  padding: 8px 16px;
  border-radius: 8px;
  flex: none;
  font-size: 12px; 
  line-height: 16px; 
  font-weight: bold;
  cursor: pointer;
}

.link {
  text-decoration: none;
}

.divider {
  background: var(--grey);
  margin: 0 16px;
  display: block;
  height: 32px;
  width: 1px;
}

.octocat {
  height: 24px;
  width: 24px;
}

.wrapper {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.in-lobby {
  /* width: 50%;
  height: 100%;
  position: relative; */
}

.in-call {
  width: 50%;
  height: 100%;
  position: relative;
}

.call-controls-wrapper {
  width: 50%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}

.call-controls {
  width: 100%;
  max-width: 620px;
  height: 100%;
}

.controls-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin: 24px;
  border-bottom: 1px solid var(--grey);
}
  
.controls-anotaciones {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    /* padding-bottom: 24px; */
    /* border-bottom: 1px solid var(--grey); */
    margin: 0px 24px;
} 

.controls-copy-url {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
}

.copy-url-action {
  display: flex;
}

.copy-url-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  min-width: 100px;
  height: 32px;
  border-radius: 8px;
  margin: 0px 8px;
  background: var(--color-primario);
  border-width: 0px;
  font-family: var(--font-family);
}

.controls-stats {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-bottom: 1px solid var(--grey);
  padding-bottom: 24px;
  margin: 0px 24px;
}

.controls-custom-example {
  margin: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--grey);
}

.stats-wrapper {
  display: flex;
  justify-content: space-between;
}

.stats-row {
  display: flex;
  justify-content: space-between;
}

.stats {
  display: flex;
  justify-content: space-between;
}

.custom-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
}

.start-container {
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.prompt {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.start-button {
  width: 280px;
  height: 32px;
  padding: 8px 16px;
  font-weight: bold;
  background: var(--green);
  border-radius: 8px;
  border-width: 0px;
  cursor: pointer;
}

.url-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;
}

.url-prompt {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--dark-grey);
  margin-bottom: 0px;
}

.url-click {
  text-decoration: underline;
  cursor: pointer;
}

.url-input {
  width: 280px;
  height: 32px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--grey);
  font-family: var(--font-family);
}

.disabled-button {
  background-color: var(--grey-lightest);
  border: 1px solid var(--grey);
}

.show {
  visibility: visible;
}

.hide {
  display: none;
}

.button:active {
  color: black;
}

.error-message {
  display: flex; 
  flex-direction: column; 
  align-items: center; 
}

.error-title {
  color: var(--red-dark); 
  font-size: 16px; 
  line-height: 24px; 
  margin: 0px; 
}

.error-description {
  font-size: 12px; 
  line-height: 16px; 
  padding: 0 16px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .header-title {
    display: none;
  }

  .left-header {
    width: 65px;
  }

  .container {
    flex-direction: column;
  }

  .url-form {
    width: 50%;
  }

  .in-call {
    width: 100%;
  }

  .in-lobby {
    width: 100%;
  }

  .call-controls-wrapper {
    width: 100%;
    margin-top: 0px;
  }

  .call-controls {
    width: 100%;
  }
}