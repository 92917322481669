@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:wght@300;400;500;600;700&display=swap');
:root{
  --color-primario: #29AAE3;
  --color-primario-hover: #1A93C7;

  --color-secundario: #093548;
  --color-secundario-hover: #041B24;

  --color-terciario: #86C332;
  --color-terciario-hover: #659226;

  --color-complementario: #fafffd;
  --color-negro: #232323;
  --color-blanco: #fff;
  --color-gris: #342E37;
  --color-rojo:#D15253;
  --color-rojo-hover:#ee6363;
  --color-naranja-suave:#FBA772;
  --color-naranja-suave-hover:#F98F4D;

  --fuente-titulo: 'Fjalla One', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Montserrat';
}

/* Disable estilos de tabla bootstrap */
table {
  border-collapse: unset;
  border-spacing: 0;
}

textarea:focus, input:focus{
  outline: none;
}

h1, h2, h3, h4, h5{
  font-family: var(--fuente-titulo);
}

p{
  margin-bottom:0!important;
}

a{
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

a:hover{
  text-decoration: none;
}

textarea{
  height:108px!important;
  padding-top: 10px!important;
}

label{
  font-size: 0.9em;
}

button{
  transition: all 0.2s ease-in-out;
}

.hidden{
  display: none!important;
}

hr{
  height: 1px;
  background-color: #D0D0D0;
  border: none;
  margin: 2em 0;
}

.noMargin{
  margin: 0 !important;
}

.noPadding{
  padding: 0!important;
}

.input{
  width: 100% !important;
  height: 2.875em;
  border: 1px solid var(--color-primario);
  border-radius: 1.25em;
  padding: 0em 0.75em;
  font-size: 1em;
  transition: all 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  margin:0 0 1em 0;
  background-color: var(--color-blanco);
}

.input::placeholder{
  font-size: 0.9em;
}

.input:focus{
  border: 2px solid var(--color-primario);
  -webkit-box-shadow: 0px 1px 11px -4px var(--color-secundario); 
  box-shadow: 0px 1px 11px -4px var(--color-secundario);
}


/*RESPONSIVE*/

/*XS*/ 
@media (max-width: 575px) {
  .noMargin-xs{
    margin: 0 !important;
  }
  
  .noPadding-xs{
    padding: 0!important;
  }
}

/*SM*/
@media (max-width: 767px) and (min-width: 576px) {
  .noMargin-sm{
    margin: 0 !important;
  }
  
  .noPadding-sm{
    padding: 0!important;
  }
}

/*MD*/
@media (max-width: 991px) and (min-width: 768px) {
  .noMargin-md{
    margin: 0 !important;
  }
  
  .noPadding-md{
    padding: 0!important;
  }
}

/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {
  .noMargin-lg{
    margin: 0 !important;
  }
  
  .noPadding-lg{
    padding: 0!important;
  }
  
}

/*XL*/
@media (min-width: 1200px){
  .noMargin-xl{
    margin: 0 !important;
  }
  
  .noPadding-xl{
    padding: 0!important;
  }
}

/* CODIGO PARA EL SPINNER DE ESPERA */

.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: var(--color-rojo);
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: var(--color-rojo);
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
