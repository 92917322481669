.login{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.login__background{
    background-color: var(--color-primario);
    height:57vh;
    position:absolute;
    width:100%;
    top:0;
    z-index: -1;
}

.login__welcomeText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:var(--color-blanco)
}

.login__welcomeText img{    
    margin: 1.5rem auto;
    height: 7em;
}

.login__welcomeText p{
    font-size: 1.4rem;
    font-weight: 200;
    margin:0 1em;
    text-align: center;
}

.login__form form{
    max-width: 445px;
    width: 100%;
    
    padding:3em 2.8125em;
    margin: 1.5em 0;
    
    filter: drop-shadow(6px 7px 4px rgba(0, 0, 0, 0.25));
    background: #FFFFFF;
    border-radius: 6px;
}

.login__form form input{
    margin:0;
    margin-top:1em
}

.login__form form input.input-error{
    border: 1px solid var(--color-rojo);
}

.login__form form small.error{
    color: var(--color-rojo);
    font-size: 0.8em;
    font-weight: 300;
    margin-top:0.5em
}

.login__form form button{
    background-color: var(--color-primario);
    border: 1px solid var(--color-primario);
    color: var(--color-blanco);
    margin-top: 1.5em;
    padding:0.8em 1.8em;
    font-size: 0.875em;
    width: 100%;
    border-radius: 2em;
    cursor: pointer;
}

.login__form form button:hover{
    background-color: var(--color-primario-hover);
    border-color: var(--color-primario-hover);
    color: var(--color-blanco);
}

.login__form a{
    display:block;
    color: var(--color-secundario);
    margin-top:2em;
}

.login__footer{
    color: var(--color-primario);
    position: absolute;
    bottom: 0;
    display:flex;
    justify-content: space-between;
    width: 100%;
    max-width: 80vw;
    margin-bottom: 1em;
}
.login__footer a{
    color:var(--color-primario);
    text-decoration: underline;
}

/* ESTILOS PARA EL BOTON DE LA ALERTA DE SWEET ALERT */

.swal2-styled.swal2-confirm{
    background-color: var(--color-primario);
}