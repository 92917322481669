header{
    font-size: 16px;
    width: 100%;
    height: 6em;
    background-color: var(--color-primario);
    color: var(--color-blanco);
    top: 0;
    z-index: 200;
    display: flex;
    justify-content: space-between;
    padding: 25px;
}

.icon__menu{
    font-size: 1em;
    width: 11.875em;
    height: 100%;
    display: flex;
    align-items: center;
}

.icon__menu i{
    font-size:2em;
    cursor: pointer;
}

.icon__menu h1{
    font-size: 1.875em;
    cursor: pointer;
    font-family: var(--fuente-titulo);
}

.icon__menu img{
    height: 4em;
}

.functions__menu{
    font-size: 1em;
    width: 28.125em;
    display: flex;
    justify-content: end;
    align-items: center;
    transition: margin-right 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.search__menu{
    background-color: var(--color-blanco);
    width: 16.25em;
    height: 2.875em;
    border-radius: 1.875em;
    display: flex;
    align-items: center;
}

.search__menu:hover{
    border: 1px solid var(--color-negro);
}

.search__menu input{
    width: 100%;
    border-radius: 1.875em;
    border:none
}

.search__menu input:focus{
    width: 100%;
    border-radius: 1.875em;
    border:none
}

.search__menu i{
    color:var(--color-negro);
    padding: 0 0.8em;
}

.profile__menu{
    display: flex;
    align-items: center;
}

.profile__menu img{
    height: 70px;
    width: 70px;
    object-fit: cover;
}

.profile__menu p{
    margin-left: 1em;
    font-size: 0.875em;
    font-weight: regular;
}

.functions__menu-margin{
    margin-right: 5em!important;
}

/*RESPONSIVE*/

/*XS*/ 
@media (max-width: 575px) {
    header{
        font-size: 12px;
    }

    .search__menu{
        display: none;
    }

    .functions__menu{
        justify-content: end;
    }
}

/*SM*/
@media (max-width: 767px) and (min-width: 576px) {
    header{
        font-size: 13px;
    }

    .search__menu{
        display: none;
    }

    .functions__menu{
        justify-content: end;
    }
}

/*MD*/
@media (max-width: 991px) and (min-width: 768px) {
    header{
        font-size: 14px;
    }
}

/*LG*/
@media (max-width: 1199px) and (min-width: 992px) {

    
}

/*XL*/
@media (min-width: 1200px){
    
}