.input{
    width: 100% !important;
    height: 2.875em;
    border: 1px solid #232323;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.02), 0px 1px 3px rgba(50, 50, 93, 0.15);
    border-radius: 0.375em;
    padding: 0em 0.75em;
    font-size: 0.9em;
    transition: all 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    margin:0 0 1em 0;
    background-color: var(--color-blanco);
}
  
.input::placeholder{
    font-size: 0.9em;
}
  
.input:focus{
    border-color: var(--color-secundario);
    -webkit-box-shadow: 0px 1px 11px -4px var(--color-secundario); 
    box-shadow: 0px 1px 11px -4px var(--color-secundario);
}

.btn-agregarMedicamento{
    background-color: var(--color-secundario);
    color:white;
    width: 100%;
}

.btn-agregarMedicamento:hover{
    background-color: var(--color-secundario-hover);
    color:white;
}