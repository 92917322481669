.table{
    width: 100%;
    font-family: 'Poppins';
    font-size: 0.875em;
    color: var(--color-negro);
    border: 1px solid var(--color-primario);
    border-radius: 8px;
    margin-top: 2em;
    text-align: left;
}

.table thead{
    height: 59px;
    vertical-align: middle;
}

.table thead tr th{
    padding: 1em;
}

.table>:not(caption)>*>* {
    border:0;
}

.table > tbody{
    vertical-align: middle;
}

.table__name{
    display: flex;
    align-items: center;
}

.table__name img{
    border-radius: 100%;
    height: 50px;
    width: 50px;
}

.table__nameDescription{
    margin-left: 1em;
    width: 100%;
}

.table__nameDescription small{
    color:var(--color-gris);
    font-weight: 300;
}

.table__fechaAlta small{
    color:var(--color-gris);
    font-weight: 300;
}

.table tbody th{
    width: 50%;
    padding: 1em;
}

.table tbody td{
    padding: 1em;
}

.table tbody tr{
    height: 92px;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

.table__estatus{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.table__estatus span{
    background-color: var(--color-terciario);
    border-radius: 100px;
    padding: 0.2em 1em;
    font-size: 11px;
    font-weight: bold;
    color: var(--color-blanco);
    cursor: default;
    text-align: center;
}

.table__estatus button{
    width: 20px;
    border: none;
    background-color: transparent;
    color:var(--color-gris);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.table__estatus-inactive{
    background-color: var(--color-rojo)!important;
}

.table td, .table th{
    vertical-align: middle;
}